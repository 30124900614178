import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'

export function scrollToFirstItemWithCompliancyError(
  breakpointValue: Breakpoint,
  listingId: string,
) {
  const insuranceCompliancyElement = document.getElementById(
    `compliancy-${listingId}`,
  )
  const insuranceElement = document.getElementById(`insurances-${listingId}`)

  if (breakpointValue >= Breakpoint.MD) {
    insuranceElement?.scrollIntoView({ block: 'start', behavior: 'smooth' })

    return
  }
  insuranceCompliancyElement?.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  })
}
