<template>
  <EmptyCart />

  <ClientOnly>
    <div v-if="cartStore.hasAvailableItems">
      <Teleport to="#checkout-side-cart-submit">
        <RevButton
          class="my-24"
          data-qa="go-to-next-side"
          :disabled="isNextButtonDisabled"
          full-width="always"
          :loading="isNextButtonLoading"
          variant="primary"
          @click="() => nextStep()"
        >
          {{
            withCheckoutContentCTA
              ? i18n(translations.buttonCheckoutABTest)
              : i18n(translations.buttonCheckout)
          }}
        </RevButton>
      </Teleport>
    </div>
  </ClientOnly>

  <div v-if="hasItems">
    <h1 class="heading-3 mb-12 mt-24 md:mb-24 md:mt-0">
      {{ i18n(translations.mainTitle) }}
    </h1>

    <CartItemCard
      v-for="item in cartStore.items"
      :key="item.listingId"
      :class="{
        'mb-56 md:mb-[88px]': getItemPopularityStats(item),
        'mb-12 md:mb-56': !getItemPopularityStats(item),
      }"
      :is-collection-point-address-set
      :is-first-item-with-unselected-insurance="
        firstItemWithUnselectedInsurance?.listingId === item.listingId
      "
      :item
      :popularity-stats="getItemPopularityStats(item)"
      :quantity="item.quantity || 1"
      :should-display-error="hasBeenSubmitted"
      @ignore-catchup-modal="nextStep"
      @remove="handleDeleteButtonClick(item)"
      @update="handleUpdateCartItem"
      @update-quantity="
        (quantity) => handleQuantitySelectorChange(item, quantity)
      "
    />

    <ItemsNotReserved class="md:hidden" />
    <Swap />
    <CrossSell />

    <ReassuranceItems>
      <BouyguesReassuranceItems
        v-if="cartStore.bouyguesMobilePlan"
        :benefits="cartStore.bouyguesMobilePlan.benefits"
      />
    </ReassuranceItems>

    <StickyBar
      :disabled="isNextButtonDisabled"
      :loading="isNextButtonLoading"
      :with-swap="swapStore.hasOffer"
      @next="nextStep"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs, useRoute, useRouter, useRuntimeConfig } from '#imports'
import { computed, onMounted, ref, watch } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import { postUpdateQuantity } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import type { CartItem } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { getPopularityStats } from '@backmarket/http-api/src/api-specs-checkout/popularity-stats/popularity-stats'
import type { PopularityStats } from '@backmarket/http-api/src/api-specs-checkout/popularity-stats/popularity-stats.types'
import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useDangerouslyComputedBreakpoint } from '@backmarket/utils/composables/useDangerouslyComputedBreakpoint'
import { useDebounceFn } from '@backmarket/utils/composables/useDebouncedFn'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { openModal } from '@ds/components/ModalBase'

import { DEBOUNCE_DELAY, MODAL_NAMES } from '~/scopes/checkout/config/constants'
import {
  hasInsuranceSelected,
  hasOfferCompliancyError,
} from '~/scopes/insurance/utils/insuranceOffers'
import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems.vue'

import BouyguesReassuranceItems from '../../components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import ItemsNotReserved from '../../components/ItemsNotReserved/ItemsNotReserved.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser'
import { CHECKOUT } from '../../routes-names'
import { useCartStore } from '../../stores/cartStore'
import { useLoaderStore } from '../../stores/loaderStore'
import { useSwapStore } from '../../stores/swapStore'

import translations from './Cart.translations'
import { scrollToFirstItemWithCompliancyError } from './Cart.utils'
import CartItemCard from './components/CartItemCard/CartItemCard.vue'
import CrossSell from './components/CrossSell/CrossSell.vue'
import EmptyCart from './components/EmptyCart/EmptyCart.vue'
import StickyBar from './components/StickyBar/StickyBar.vue'
import Swap from './components/Swap/Swap.vue'

const { openErrorToast } = useTheToast()

const experiments = useExperiments()
const authStore = useAuthStore()
const cartStore = useCartStore()
const loaderStore = useLoaderStore()
const swapStore = useSwapStore()
const runtimeConfig = useRuntimeConfig()
const tracking = useTracking()
const router = useRouter()

const i18n = useI18n()
const route = useRoute()

const {
  market: { countryCode },
} = useMarketplace()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const breakpoint = useDangerouslyComputedBreakpoint()

const { availableItems } = storeToRefs(cartStore)

const hasBeenSubmitted = ref(false)

const popularityStats = ref<PopularityStats[]>([])

const firstItemWithUnselectedInsurance = computed(() =>
  cartStore.items.find(
    ({ insuranceOffers }) => !hasInsuranceSelected(insuranceOffers),
  ),
)

const hasItems = computed(() => !isEmpty(cartStore.items))

const isCollectionPointAddressSet = computed(
  () => !isEmpty(cartStore.lastCollectionPoint),
)

const isNextButtonLoading = computed(() => cartStore.isCartLoading)
const isNextButtonDisabled = computed(
  () =>
    !cartStore.hasAvailableItems ||
    cartStore.hasUnavailableItems ||
    isNextButtonLoading.value,
)

const withCheckoutContentCTA = computed(
  () =>
    experiments['experiment.checkoutContentCTA'] === 'checkoutContentDetailed',
)

const isWithSalesPopularityTag = computed(
  () =>
    experiments['experiment.newSalesPopularityTag'] ===
    'popularityTagDisplayed',
)

const getItemPopularityStats = (item: CartItem) =>
  popularityStats.value.find(
    (popularityItem) => popularityItem.productPublicId === item.akeneoId,
  )

function trackFunnel() {
  if (typeof route.name === 'string') {
    tracking.trackFunnel(cartStore.trackingData(route.name))
  }
}

async function fetchPopularityStats() {
  if (cartStore.hasAvailableItems && isWithSalesPopularityTag.value) {
    const data = await $httpFetch(getPopularityStats, {
      queryParams: {
        pid: cartStore.items.map((item) => item.akeneoId).join(','),
      },
    })
    popularityStats.value = data
  }
}

onMounted(async () => {
  trackFunnel()
  fetchPopularityStats()
})

const nextStep = async () => {
  const firstItemWithCompliancyError = cartStore.items.find(
    ({ insuranceOffers }) => insuranceOffers.some(hasOfferCompliancyError),
  )

  tracking.trackClick({
    name: 'go_checkout',
    zone: 'cart',
    hasCompliancyError: Boolean(firstItemWithCompliancyError),
  })

  hasBeenSubmitted.value = true

  if (firstItemWithCompliancyError) {
    scrollToFirstItemWithCompliancyError(
      breakpoint.value,
      firstItemWithCompliancyError.listingId,
    )

    // block nextStep if compliancy is not checked
    return
  }

  // Catch Up Modal
  const itemsWithSelectableInsurances = cartStore.items.filter(
    ({ insuranceOffers }) => !hasInsuranceSelected(insuranceOffers),
  )

  const hasCartOnlyOneInsuranceSelectable =
    itemsWithSelectableInsurances.length === 1

  const isCatchUpModalEnabled =
    !runtimeConfig.public.KILL_INSURANCE_CATCHUP_MODAL &&
    cartStore.showCatchupModal &&
    countryCode !== MarketCountryCode.JP

  if (hasCartOnlyOneInsuranceSelectable && isCatchUpModalEnabled) {
    hasBeenSubmitted.value = false
    openModal(MODAL_NAMES.CATCH_UP_INSURANCE_OFFER)

    // block nextStep if modal is opened
    return
  }

  try {
    if (authStore.authenticated) {
      // check auth state and proceed
      loaderStore.enable()

      router.push({
        name: cartStore.isShippable
          ? CHECKOUT.ADDRESS_CONFIRMATION
          : CHECKOUT.SHIPPING_ADDRESS,
      })
    } else {
      router.push({
        name: CHECKOUT.AUTHENTICATION,
      })
    }
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[Checkout] Unhandled error going to the next step',
    )
  } finally {
    loaderStore.disable()
  }
}

const updateItem = async (item: CartItem, action: 'delete' | 'update') => {
  try {
    await $httpFetch(postUpdateQuantity, {
      body: {
        action,
        listingId: item.listingId,
        newQuantity: item.quantity,
        listingPrice: item.price,
      },
    })
    if (action === 'delete') {
      const product = cartStore
        .trackingData(route.name as string)
        .products.find(
          (trackingProduct) => item.productId === trackingProduct.id,
        )

      if (product) {
        tracking.trackRemoveFromCart({
          product,
        })
      }
    }

    await cartStore.fetchCart()

    trackFunnel()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating quantity',
    )
  }
}

const handleDeleteButtonClick = async (item: CartItem) => {
  cartStore.setIsCartLoading()
  await updateItem(item, 'delete')
}

const handleQuantitySelectorChange = useDebounceFn(
  async function doUpdateQuantity(item: CartItem, quantity: number) {
    cartStore.setIsCartLoading()
    await updateItem({ ...item, quantity }, 'update')
  },
  DEBOUNCE_DELAY,
)

//  This method udpate items in the cart without fetching the cart again
async function handleUpdateCartItem(udpatedItem: CartItem) {
  try {
    const udpatedItems = cartStore.items.reduce<CartItem[]>((items, item) => {
      if (item.listingId === udpatedItem.listingId) {
        return [...items, udpatedItem]
      }

      return [...items, item]
    }, [])

    cartStore.setItems(
      udpatedItems,
      experiments['experiment.monthlyFilterExperiment'],
    )

    trackFunnel()
  } catch {
    openErrorToast()
  } finally {
    await cartStore.fetchCart()
  }
}

watch(
  () => availableItems.value,
  () => fetchPopularityStats(),
)
</script>
